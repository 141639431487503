var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-select", {
    staticClass: "w-full",
    attrs: {
      label: "Name",
      "show-no-options": false,
      "show-no-results": false,
      options: _vm.locations,
      clearable: false,
      placeholder: "Selecione uma opção"
    },
    on: {
      input: function($event) {
        return _vm.onLocationSelect(_vm.selectedLocation)
      }
    },
    scopedSlots: _vm._u([
      {
        key: "option",
        fn: function(option) {
          return [
            _c("span", { class: [option.Icon, "icon-font"] }),
            _vm._v("\n    " + _vm._s(option.Name) + "\n  ")
          ]
        }
      }
    ]),
    model: {
      value: _vm.selectedLocation,
      callback: function($$v) {
        _vm.selectedLocation = $$v
      },
      expression: "selectedLocation"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }